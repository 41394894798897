import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src={profilepic} alt="Shreya Yalamanchili Profile Pic" />
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>{bio}</p>
            <div className="row">
               <div className="columns download">
               <table style={{width:'400px'}}>
               <tr>
                  <td><a href="mailto:yalamanchili.s@northeastern.edu" className="button">contact me</a> </td>
                  <td><a href='https://drive.google.com/file/d/1ByuYIZn2OfwbFpyoeG1mkRZu6f0kZv9w/view?usp=sharing' target='_blank' className="button">view resume</a></td>
                  </tr>
                  </table>
               </div>
            </div>
         </div>
      </div>

   </section>
    );
  }
}

export default About;
