import React, { Component } from 'react';

class Contact extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var message = this.props.data.contactmessage;
    }

    return (
      <section id="contact">

         <div className="row section-head">


            <div>

                  <p className="lead" style={{fontFamily: 'opensans-bold', fontSize:'25px', left: '50%', marginLeft: '60px'}}>{message}</p>

            </div>

         </div>

         <div>
            <div>



                  <div>
                  <table style={{left: '50%', marginLeft: '-215px', width:'400px', position:'absolute'}}>
                  <tr>
                    <td> <a href="mailto:yalamanchili.s@northeastern.edu" className="button">contact me</a> </td>
                    <td> <a href='https://drive.google.com/file/d/1ByuYIZn2OfwbFpyoeG1mkRZu6f0kZv9w/view?usp=sharing' target='_blank' className="button">view resume</a> </td>
                  </tr>
                  </table>
                  </div>



           </div>


      </div>
   </section>
    );
  }
}

export default Contact;
