import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import React, { Component } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class Resume extends Component {
  render() {


    if(this.props.data){
      var skillmessage = this.props.data.skillmessage;

      var skills = this.props.data.skills.map(function(skills){
        var className = 'bar-expand '+skills.name.toLowerCase();
        return <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name}</em></li>
      })
    }

    return (
      <section id="resume">
      <div className="row education">
         <div className="three columns header-col">
            <h1 style={{marginTop:"50px", marginBottom:"50px"}}><span>Experience</span></h1>
         </div>
            <p></p>

      <Timeline lineColor={'#9CC2E5'}>
        <TimelineItem
          key="001"
          dateComponent={(
            <div
              style={{
                display: 'block',
                float: 'left',
                background: 'none',
                color: '#595959',
                fontSize: '20px',
                fontFamily: 'opensans-light'
              }}
            >
              January 2020 - Present
            </div>
          )}
        >
          <h3 style={{fontSize: '20px', fontFamily: 'opensans-bold', color: '#9CC2E5'}}>bluebird bio</h3>
          <h4 style={{paddingBottom: '12px', color: '#595959', fontSize: '18px', fontFamily: 'opensans-regular'}}>Business Process Analyst Co-Op</h4>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>

            bluebird bio is a biotech company that is developing pioneering gene therapies for rare and severe genetic disorders.
            Currently, the company is working on treatment for three genetic disorders and one form of cancer. At bluebird, my role
            involved developing solutions to resolve business inefficiencies. My main achievements during this co-op include:
          </p>
          <ul style={{paddingLeft: '26px', listStyleType: 'disc', color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          <li>Overhauled 15 patient operation key performance indicators to make end-to-end data tracking more efficient</li>
          <li>Analyzed supply chain data using R to identify and resolve outstanding deviations</li>
          <li>Spearheaded sandbox testing of cell traceability system to mitigate operational risks</li>
          <li>Created user-friendly dashboards using Power BI to visualize patient metrics</li>
          </ul>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          I was also offered extension to continue working with the team part-time until the end of year.
          </p>
        </TimelineItem>
        <TimelineItem
          key="002"
          dateComponent={(
            <div
              style={{
                display: 'block',
                float: 'left',
                background: 'none',
                color: '#595959',
                fontSize: '20px',
                fontFamily: 'opensans-light'
              }}
            >
              May 2019 - June 2019
            </div>
          )}
        >
          <h3 style={{fontSize: '20px', fontFamily: 'opensans-bold', color: '#9CC2E5'}}>Drago Conserve</h3>
          <h4 style={{paddingBottom: '12px', color: '#595959', fontSize: '18px', fontFamily: 'opensans-regular'}}>Management Consultant</h4>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
            Drago Conserve is a fish preservation and bottling company located near Catania, Italy. Drago specializes in preserving fish with
            minimal processing, completing almost all of its work by hand. I worked with a team of four others to consult with the company
            regarding future technological improvements and international expansion. Some of my responsibilities included:
          </p>
          <ul style={{paddingLeft: '26px', listStyleType: 'disc', color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          <li>Consulted for the local business to create marketing, globalization, and sales strategies</li>
          <li>Designed an e-commerce app by utilizing market research and user interviews</li>
          <li>Co-headed a subproject which proposed the company become a strategic partner with Emirates Airlines to sample their products on flights</li>
          <li>Co-authored a 32-page marketing plan and presented to company executives</li>
          </ul>
        </TimelineItem>
        <TimelineItem
          key="003"
          dateComponent={(
            <div
              style={{
                display: 'block',
                float: 'left',
                background: 'none',
                color: '#595959',
                fontSize: '20px',
                fontFamily: 'opensans-light'
              }}
            >
              July 2018 - February 2019
            </div>
          )}
        >
          <h3 style={{fontSize: '20px', fontFamily: 'opensans-bold', color: '#9CC2E5'}}>ALOHA Mind Math</h3>
          <h4 style={{paddingBottom: '12px', color: '#595959', fontSize: '18px', fontFamily: 'opensans-regular'}}>Web Developer</h4>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
            ALOHA Mind Math is a math tutoring center which uses the abacus to help with right-brain development in children. At ALOHA,
            I worked to build an internal website for my local branch to keep track of inventory management as well as different events.
            The following describes some of the features of the site:
          </p>
          <ul style={{paddingLeft: '26px', listStyleType: 'disc', color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          <li>Built a password-protected website to organize inventory and event information</li>
          <li>Incorporated role-based security features within the template to enable universal access by employees</li>
          <li>Implemented a SQL-based database to manage and support inventory and event data for internal use</li>
          <li>Developed a consistent template across all pages and platforms to ensure efficient use of the website</li>
          </ul>
        </TimelineItem>
      </Timeline>








         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
               </div>
            </div>
         </div>
      </div>




      <div className="row skill">

         <div className="three columns header-col">
            <h1 style={{marginTop: "60px", marginBottom: '-14px'}}><span>Skills</span></h1>
         </div>

         <div className="nine columns main-col">

            <p style={{marginLeft: "-100px", fontSize: "20px", marginTop: "60px", marginBottom: '-15px', fontFamily: "opensans-light", color:"#595959"}}>
            Here are some of my top skills
            </p>

				<div className="bars" style={{fontFamily: 'opensans-bold'}}>

        <table style={{borderCollapse: 'separate', borderSpacing: '50px 60px'}}>
          <tr>
            <td><CircularProgressbar value={85} text={'Java'} styles={buildStyles({textSize: '14px', pathColor: '#9CC2E5', textColor: '#9CC2E5'})}/></td>
            <td><CircularProgressbar value={75} text={'HTML/CSS'} styles={buildStyles({textSize: '14px', pathColor: '#9CC2E5', textColor: '#9CC2E5'})}/></td>
            <td><CircularProgressbar value={70} text={'SQL'} styles={buildStyles({textSize: '14px', pathColor: '#9CC2E5', textColor: '#9CC2E5'})}/></td>
            <td><CircularProgressbar value={70} text={'JavaScript'} styles={buildStyles({textSize: '14px', pathColor: '#9CC2E5', textColor: '#9CC2E5'})}/></td>
          </tr>
          <tr>
            <td><CircularProgressbar value={65} text={'VBA'} styles={buildStyles({textSize: '14px', pathColor: '#9CC2E5', textColor: '#9CC2E5'})}/></td>
            <td><CircularProgressbar value={65} text={'Python'} styles={buildStyles({textSize: '14px', pathColor: '#9CC2E5', textColor: '#9CC2E5'})}/></td>
            <td><CircularProgressbar value={60} text={'R'} styles={buildStyles({textSize: '14px', pathColor: '#9CC2E5', textColor: '#9CC2E5'})}/></td>
            <td><CircularProgressbar value={60} text={'Racket'} styles={buildStyles({textSize: '14px', pathColor: '#9CC2E5', textColor: '#9CC2E5'})}/></td>
          </tr>
        </table>
				</div>
			</div>
      </div>
      <p>
      {"\n"}
      </p>
   </section>
    );
  }
}


export default Resume;
