import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [maxWidth, setMaxWidth] = React.useState('md');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = (scrollType) => () => {
    setOpen1(true);
    setScroll(scrollType);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen2 = (scrollType) => () => {
    setOpen2(true);
    setScroll(scrollType);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return (
    <div id="portfolio" style={{width:"100%", textAlign:"center"}}>
    <div className="row skill">

       <div className="three columns header-col">
          <h1 style={{marginLeft:"-123px", marginBottom:"50px"}}><span>projects</span></h1>
       </div>

       <div className="nine columns main-col">

          <p style={{marginLeft: "-595px", fontSize: "20px", color:"#595959", fontFamily: "opensans-light", marginBottom:"50px"}}>
          Check out a few of my recent projects
          </p>
      </div>
    </div>


      <div className="leftButton" style={{marginBottom: "50px"}} onClick={handleClickOpen('paper')}>
      <h2 style={{fontFamily: "opensans-bold", fontSize: "23px", color:"#FFF", marginBottom:"30px", marginTop:"50px"}}>Beyond gOOD</h2>
      <p style={{color: '#fff', fontSize: '18px', fontFamily: 'opensans-light', margin:"18px"}}>A replication of a Microsoft Excel spreadsheet, built using Java. Supports
      some basic and advanced features of the original software.</p>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        maxWidth={maxWidth}
        aria-labelledby="title"
        aria-describedby="description"
      >
        <DialogTitle id="title">
          <h2 style={{fontFamily: "opensans-bold", color:"#9CC2E5"}}>Beyond gOOD</h2>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="description"
            ref={descriptionElementRef}
            tabIndex={-1}>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          Beyond gOOD was the final project for my Object Oriented Design class. For this project, we made a simplistic replica of Microsoft Excel.
          Our version supported some of the basic features, such rendering and evaluating cells, displaying formulas, and selecting a cell.
          We also implemented more advanced components, like the ability to select a region of cells, creating graphs, infinite scrolling, and editing cells.
          </p>
          <img src="images/TriangleNumbersOutput.jpg" alt="One of our output files during testing."/>
          <p style={{color: '#595959', fontSize: '14px', fontFamily: 'opensans-light', textAlign: "center"}}>
          One of our output files during testing. This file has a formula which calculates the first 50 triangular numbers. The formula edit bar is shown
          at the top of the image, with options to accpt or reject a formula and one of the formulas displayed.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          One of our biggest challenges during the development of this program was catching circular references. A circular reference is a formula that refers to
          its own cell value. Catching indirect circular references, which are references that link to other cells before including the circular references,
          were difficult to detect due to the need to step down into each part of the formula.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          This program was created using Java. It uses the model-view-controller (MVC) design pattern. This architecture choice allowed for future updates
          to easily be incorporated into the program, as well as maintained independence among the different versions of the program. Users are able to
          select from one of six different views, each of which provided slightly different functionality (for example, opening a given file, opening a blank
          worksheet, etc.)
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          If you would like to learn more, please reach out to me! The code and a lot of our design/implementation choices are currently private due to class policies.
          </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>


      <div className="middleButton1" style={{marginBottom: "50px"}} onClick={handleClickOpen1('paper')}>
      <h2 style={{fontFamily: "opensans-bold", fontSize: "23px", color:"#FFF", marginBottom:"30px", marginTop:"50px"}}>JobChampions</h2>
      <p style={{color: '#fff', fontSize: '18px', fontFamily: 'opensans-light', margin:"18px"}}>An all-in-one career services website, with information on
      everything from writing a resume and cover letter to acing the interview.</p>
      </div>
      <Dialog
        open={open1}
        onClose={handleClose1}
        scroll={scroll}
        maxWidth={maxWidth}
        aria-labelledby="title"
        aria-describedby="description"
      >
        <DialogTitle id="title">
          <h2 style={{fontFamily: "opensans-bold", color:"#9CC2E5"}}>JobChampions</h2>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="description"
            ref={descriptionElementRef}
            tabIndex={-1}>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          JobChampions is a comprehnsive career services website. Early in college, I noticed that I was not able to find all the information I needed
          to recruit for jobs and internships. This led to the creation of JobChampions, which allows users to find information on every step of the job-finding process,
          reducing the information overload associated with the topic. I was the lead developer and designer on this project, and worked with a team of two others.
          </p>
          <video width="910" height="540" controls>
            <source src="images/jobChampionsVid.mp4" type="video/mp4"/>
          </video>
          <p style={{color: '#595959', fontSize: '14px', fontFamily: 'opensans-light', textAlign: "center"}}>
          The resume and cover letter section of the website. The content was designed in a step-by-step format so it would be easy to follow and jump to different
          steps. Icons were used to differentiate between pages and add an extra aesthetic element.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          To bring JobChampions from an idea to an actual service, we first had to conduct extensive user research to determine who our target audience was and what they
          most needed from the site. From this research, we learned that our audience included young adults and anyone looking to re-enter the workforce. These individuals
          needed help finding complete information, and did not know how to enter into the professional business world.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          Following this, I designed the theme and logo of the site and began creating content. We separated content into distinct categories: find a job, resumes and cover
          letters, applications, interviews, and more. This allowed for ease of access to our site and created a much more streamlined experience.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          One of the most important features on the site is our "We Do It For You" services, which include a custom resume and cover letter builder. I developed two automated document
          generation forms which allow users to input their personal information and download a resume and cover letter. These forms increase efficiency for the end user by simplifying
          the document-writing experience.
          </p>
          <img src="images/jobChampionsResume.jpg" alt="The resume and cover letter builder."/>
          <p style={{color: '#595959', fontSize: '14px', fontFamily: 'opensans-light', textAlign: "center"}}>
          The resume and cover lettter builders. On the left, users can fill out the form to download a fully formatted resume. On the right, users can replace the green
          text with their information and download a personalized cover letter.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          We also ensured that the website was optimized for mobile and was supported by all major browsers The site ended up gaining some traction, with over 150
          individual visits within the first week of launch. Many students reached out to us saying that it was a good starting point for their job search.
          </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>



      <div className="middleButton2" style={{marginBottom: "50px"}} onClick={handleClickOpen3('paper')}>
      <h2 style={{fontFamily: "opensans-bold", fontSize: "23px", color:"#FFF", marginBottom:"30px", marginTop:"50px"}}>Light Em' All</h2>
      <p style={{color: '#fff', fontSize: '18px', fontFamily: 'opensans-light', margin:"18px"}}>A game where users have to rotate and connect wired tiles
      before lighting all the wires by moving a power source to the correct spot.</p>
      </div>
      <Dialog
        open={open3}
        onClose={handleClose3}
        scroll={scroll}
        maxWidth={maxWidth}
        aria-labelledby="title"
        aria-describedby="description"
      >
        <DialogTitle id="title">
          <h2 style={{fontFamily: "opensans-bold", color:"#9CC2E5"}}>Light Em' All</h2>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="description"
            ref={descriptionElementRef}
            tabIndex={-1}>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          Light Em' All is a game I developed as the final project for my Fundamentals of Computer Science 2 class. To play the game, a board with scrambled wired
          tiles is created. First, users must rotate the tiles by clicking on them to connect all of the wires. After the wires have been connected, the user can use
          the arrow keys to move a power source along the wired path. To win the game, the power source must be positioned so that all of the connected wires are lit.
          </p>
          <img src="images/lightEmAll.jpg" alt="The gamee board." style={{marginLeft: "25%"}}/>
          <p style={{color: '#595959', fontSize: '14px', fontFamily: 'opensans-light', textAlign: "center"}}>
          An example of the game board after winning. All of the wires are connected and lit.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          This game was developed in three separate versions: first, placing the wires via a hard coded version, then using breadth-first-search and depth-first-search,
          and finally using Kruskal's Algorithm. These algorithms were used to generate a random board that could be solved. The final version used a minimum spanning tree
          and sorted using a heapsort. Using the algorithms ensured that any size board could be crated, and that each board had a solution. It also allowed the power
          source to have added functionality, such as the light becoming dimmer as it flowed away from the source.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          This program was created using Java and the Java world library. If you would like to learn more, please reach out to me! The code and a lot of my
          design/implementation choices are currently private due to class policies.
          </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>


      <div className="rightButton" style={{marginBottom: "50px"}} onClick={handleClickOpen2('paper')}>
      <h2 style={{fontFamily: "opensans-bold", fontSize: "23px", color:"#FFF", marginBottom:"30px", marginTop:"50px"}}>ALOHA CM</h2>
      <p style={{color: '#fff', fontSize: '18px', fontFamily: 'opensans-light', margin:"18px"}}>An employee-facing website developed to organize inventory
      and event planning information for a small business.</p>
      </div>
      <Dialog
        open={open2}
        onClose={handleClose2}
        scroll={scroll}
        maxWidth={maxWidth}
        aria-labelledby="title"
        aria-describedby="description"
      >
        <DialogTitle id="title">
          <h2 style={{fontFamily: "opensans-bold", color:"#9CC2E5"}}>ALOHA Center Management</h2>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="description"
            ref={descriptionElementRef}
            tabIndex={-1}>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          ALOHA Center Management (CM) is a website I developed for ALOHA Mind Math, a local math tutoring business. The website's main purpose is to help manage
          inventory and event planning information for the business. Previously, the company managed all of their information on paper, which was extremely inefficient
          and led to delays in receiving inventory and loss of capital. After joining the firm in 2018, I realized that there had to be an easier way to keep track of the
          information, leading to the creation of the site.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          ALOHA CM is divided into two main pages: inventory and events. From there, users can add items to the corresponding section through a modal popup. This modal
          includes a short form with basic information, such as the name of the item, quantity needed, order date, and more. Users can also edit and delete items from
          both pages.
          </p>
          <img src="images/alohacmInventory.jpg" alt="The inventory page of the website." style={{marginLeft: "15%"}}/>
          <p style={{color: '#595959', fontSize: '14px', fontFamily: 'opensans-light', textAlign: "center"}}>
          The inventory pagee, with options add an item as well as edit and delete each entry.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          The website is linked to a database so that the information users enter is saved after being inputted. The database allows users to access data in the future,
          and also allows for editing and deleting entries. PHP and SQL were used to maintain the database and entires.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          CSS was used to customize the pages of the website,
          fit them to the standards of the business, and make the website more consistent and uniform. While the site is a back-end application, having it match the established
          theme of the business was important. CSS also improved visibility of the entries in the tables, as it made the information more readable.
          </p>
          <p style={{color: '#595959', fontSize: '18px', fontFamily: 'opensans-light'}}>
          For added security, the website was password protected. To access the contents of the site, employees must enter a username and password.
          </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>




    </div>
  );
}
