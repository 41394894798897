import React, { Component } from 'react';
import InstagramEmbed from 'react-instagram-embed';

class Testimonials extends Component {
  render() {

    if(this.props.data){
      var testimonials = this.props.data.testimonials.map(function(testimonials){
        return  <li key={testimonials.user}>
            <blockquote>
               <p>{testimonials.text}</p>
               <cite>{testimonials.user}</cite>
            </blockquote>
         </li>
      })
    }

    return (
      <section id="testimonials">
      <div className="text-container" style={{marginBottom:"50px", marginTop:"30px"}}>
         <div className="row">
         <h1>art/design</h1>
         <div className="nine columns main-col">

            <p style={{fontSize: "20px", color:"#595959", marginLeft:"152px", marginTop:"-41px", fontFamily: "opensans-light"}}>
            Take a look at some of my artwork
            </p>
        </div>

         <table>
          <tr>
            <th>
            <InstagramEmbed
             url='https://www.instagram.com/p/B5YqQQ_HBMh/'
             maxWidth={320}
             hideCaption={true}
             containerTagName='div'
             protocol=''
             injectScript
             style={{marginTop: '30px', marginRight: '20px', marginBottom: '50px'}}/>
             </th>
            <th>
            <InstagramEmbed
             url='https://www.instagram.com/p/CAA80ypHwpG/'
             maxWidth={320}
             hideCaption={true}
             containerTagName='div'
             protocol=''
             injectScript
             style={{marginLeft: '20px', marginTop: '30px', marginRight: '20px', marginBottom: '50px'}}/></th>
            <th>
            <InstagramEmbed
             url='https://www.instagram.com/p/B8mEx9ynxQW/'
             maxWidth={320}
             hideCaption={true}
             containerTagName='div'
             protocol=''
             injectScript
             style={{marginLeft: '20px', marginTop: '30px', marginRight: '20px', marginBottom: '50px'}}/></th>
          </tr>
        </table>



            </div>
         </div>
   </section>
    );
  }
}

export default Testimonials;
